import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import { BaseTextV2Secondary, H2v2 } from 'components/Text'
import theme from 'theme'
import { useGetOrderItemsQuery } from 'containers/Orders/Details/rtkApi'
import Pagination from 'components/Pagination'
import Loader from 'components/Loader'
import OrderItem from 'views/Orders/Details/OrderItemsList/OrderItem'
import RouteDeadlineBanner from './RouteDeadlineBanner'

import {
  OrderItemsListWrapper,
  OrderListHeaderWrapper,
  OrderListItemsWrapper,
  PaginationWrapper,
} from './styles'
import messages from '../messages'

const OrderItemsList = ({ orderId, orderDetails }) => {
  const { formatMessage } = useIntl()
  const [page, setPage] = useState(1)

  const {
    data: { items, pagination } = {},
    isFetching,
  } = useGetOrderItemsQuery({ orderId, page })
  const { totalCount, currentPage, totalPages } = pagination || {}

  return (
    <OrderItemsListWrapper>
      <OrderListHeaderWrapper>
        <H2v2>{formatMessage(messages.orderedProducts)}</H2v2>
        {totalCount && (
          <BaseTextV2Secondary $fontWeight={theme.fontWeights.medium}>
            {`${totalCount} ${formatMessage(messages.numberOfProducts, {
              number: totalCount,
            })}`}
          </BaseTextV2Secondary>
        )}
      </OrderListHeaderWrapper>
      <RouteDeadlineBanner orderDetails={orderDetails} />
      {isFetching ? (
        <Loader />
      ) : (
        <>
          <OrderListItemsWrapper>
            {items.map((orderItem, index) => (
              <OrderItem
                orderItem={orderItem}
                listIndex={index}
                key={`${orderItem.productId}-${orderItem.unit.name}`}
              />
            ))}
          </OrderListItemsWrapper>
          <PaginationWrapper>
            <Pagination
              current={currentPage}
              totalPages={totalPages}
              onChange={nextPage => setPage(nextPage)}
            />
          </PaginationWrapper>
        </>
      )}
    </OrderItemsListWrapper>
  )
}

export default OrderItemsList

import { rtkApi } from 'services/api/rtkApi'

import { RTK_INCOMING_ORDERS_TAG } from 'containers/Cart/rtkApi'
import createFormData from 'utils/createFormData'
import { RTK_ORDERS_LIST_TAG } from '../List/rtkApi'

export const RTK_ORDER_DETAILS_TAG = 'Order'
export const RTK_ORDER_DETAILS_ITEMS_TAG = 'OrderItems'

export const orderApi = rtkApi
  .enhanceEndpoints({
    addTagTypes: [RTK_ORDER_DETAILS_TAG, RTK_ORDER_DETAILS_ITEMS_TAG],
  })
  .injectEndpoints({
    endpoints: build => ({
      getOrderDetails: build.query({
        query: ({ orderId, suppress404Redirect }) => ({
          url: `me/orders/${orderId}`,
          meta: {
            suppress404Redirect,
          },
        }),
        // use only orderId as cache key
        serializeQueryArgs: ({ queryArgs }) => queryArgs.orderId,
        providesTags: (result, error, params) => [
          { type: RTK_ORDER_DETAILS_TAG, id: params.orderId },
        ],
      }),
      getOrderItems: build.query({
        query: ({ orderId, perPage = 24, page = 1 }) => ({
          url: `me/orders/${orderId}/items`,
          params: {
            perPage,
            page,
          },
        }),
        transformResponse: res => ({
          items: res.items,
          pagination: res.meta.pagination,
        }),
        providesTags: (result, error, params) => [
          { type: RTK_ORDER_DETAILS_ITEMS_TAG, id: params.orderId },
        ],
      }),
      getAffectedOrders: build.query({
        query: ({ orderId }) => ({
          url: `me/orders/${orderId}/cancelation/affected_orders`,
        }),
      }),
      cancelOrder: build.mutation({
        query: ({ orderId, affectedOrderIds, reasons }) => ({
          url: `me/orders/${orderId}/cancelation`,
          method: 'POST',
          data: { affectedOrderIds, reasons },
        }),
        invalidatesTags: (result, error, params) => {
          const affectedOrdersTags = params.affectedOrderIds.map(id => ({
            type: RTK_ORDER_DETAILS_TAG,
            id: String(id),
          }))

          return [
            ...affectedOrdersTags,
            { type: RTK_ORDER_DETAILS_TAG, id: params.orderId },
            RTK_ORDERS_LIST_TAG,
            RTK_INCOMING_ORDERS_TAG,
          ]
        },
      }),
      sendComplaintForm: build.mutation({
        query: ({ orderId, formValues }) => ({
          url: `me/orders/${orderId}/complaints`,
          method: 'POST',
          data: createFormData({ data: formValues }),
        }),
      }),
      sendFeedbackForm: build.mutation({
        query: ({ orderId, formValues }) => ({
          url: `me/orders/${orderId}/feedbacks`,
          method: 'POST',
          data: { details: formValues },
        }),
      }),
    }),
  })

export const {
  useGetOrderDetailsQuery,
  useGetOrderItemsQuery,
  useGetAffectedOrdersQuery,
  useCancelOrderMutation,
  useSendComplaintFormMutation,
  useSendFeedbackFormMutation,
} = orderApi
